import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import Table, { TableHeader } from "../base/Table";
import {
  clearUsers,
  getUsersWithPagination,
  userDataLimit,
} from "../store/actions/userActions";
import { userStateInterface } from "../store/reducers/userReducer";

interface Props {
  userStore: userStateInterface;
  getUsersWithPagination: (paginationStartAt: string | Date) => void;
  clearUsers: () => void;
}

interface State {
  pageIndex: number;
}

class Users extends Component<Props> {
  state: State = {
    pageIndex: 1,
  };

  componentDidMount = () => {
    this.props.getUsersWithPagination("");
  };

  componentWillUnmount = () => {
    this.props.clearUsers();
  };

  handlePagination = (value: string) => {
    if (value === "next") {
      if (
        this.props.userStore.lastCursor &&
        this.state.pageIndex * userDataLimit + 1 >
          this.props.userStore.users.length
      ) {
        this.props.getUsersWithPagination(this.props.userStore.lastCursor);
      }
      this.setState({ pageIndex: this.state.pageIndex + 1 });
    } else {
      this.setState({ pageIndex: this.state.pageIndex - 1 });
    }
  };

  renderDataSource = () => {
    let dataSource: Object[] = [];

    if (this.props.userStore.users) {
      this.props.userStore.users.map((eachUser) => {
        dataSource.push({
          id: eachUser.id,
          email: eachUser.email,
          createdAt: moment(eachUser.createdAt.seconds * 1000).format(
            "DD/MM/YY"
          ),
        });
        return "";
      });
    }
    return dataSource;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "email",
        title: "Email",
      },
      {
        key: "createdAt",
        title: "Created At",
      },
    ];

    return (
      <div className="mt-8">
        <div className="max-w-6xl mx-auto my-4 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex flex-col">
              <h3 className="text-xl leading-6 font-bold text-gray-900">
                Users (Total : {this.props.userStore.users.length})
              </h3>
            </div>
            <div className="flex-grow" />
          </div>
        </div>

        <Table
          dataLimit={userDataLimit}
          header={headers}
          loading={this.props.userStore.loading}
          pageIndex={this.state.pageIndex}
          lastCursor={this.props.userStore.lastCursor}
          data={this.renderDataSource()}
          handlePage={this.handlePagination}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userStore: state.userStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearUsers: () => dispatch(clearUsers()),
    getUsersWithPagination: (paginationStartAt: string | Date) =>
      dispatch(getUsersWithPagination(paginationStartAt)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);
