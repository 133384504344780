import { combineReducers } from "redux";
import authReducer from "./authReducer";
import firmwareReducer from "./firmwareReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  authStore: authReducer,
  firmwareStore: firmwareReducer,
  userStore: userReducer,
});

export default rootReducer;
