import {
  CameraIcon,
  HomeIcon,
  UserCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import DashboardLogo from "../../images/kaltech_text_logo.png";
import IconButton from "../base/IconButton";

interface Props {
  page: DashboardPage;
  leftNavMobileMenu: boolean;
  handleToggleLeftNavMobileMenu: () => void;
}

export enum DashboardPage {
  USERS = "Users",
  FIRMWARE = "Firmwares",
}

export default class LeftNav extends Component<Props> {
  renderDashboardLogo = () => {
    return (
      <Link to="/dashboard">
        <div className="flex items-center flex-shrink-0 px-6 cursor-pointer">
          <img className="h-5 w-auto" src={DashboardLogo} alt="Favicon" />
        </div>
      </Link>
    );
  };

  renderLeftNavItems = (index: DashboardPage, selection: DashboardPage[]) => {
    let title = "";
    let icon = <HomeIcon />;
    let path = "";

    let defaultClass =
      "text-gray-500 hover:bg-blue-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer hover:text-white";
    let defaultSvgClass = "text-gray-500 mr-3 h-6 w-6 group-hover:text-white";

    if (selection.includes(this.props.page)) {
      defaultClass =
        "bg-blue-600 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer";
      defaultSvgClass = "text-white mr-3 h-6 w-6";
    }

    switch (index) {
      case "Users":
        title = "Users";
        icon = <UserCircleIcon className={defaultSvgClass} />;
        path = "/dashboard";
        break;
      case "Firmwares":
        title = "Firmwares";
        icon = <CameraIcon className={defaultSvgClass} />;
        path = "/dashboard/firmwares";
        break;
      default:
        break;
    }

    return (
      <Link to={path}>
        <p className={defaultClass} aria-current="page">
          {icon}
          {title}
        </p>
      </Link>
    );
  };

  render() {
    return (
      <>
        {this.props.leftNavMobileMenu && (
          <div
            className="fixed inset-0 flex z-40 lg:hidden"
            role="dialog"
            aria-modal="true"
          >
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-75"
              onClick={this.props.handleToggleLeftNavMobileMenu}
              aria-hidden="true"
            ></div>
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <IconButton
                  icon={<XIcon className="h-5 w-5 text-white" />}
                  background="bg-transparent"
                  className="text-white ml-1 rounded-md flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo"
                  onClick={this.props.handleToggleLeftNavMobileMenu}
                />
              </div>
              {this.renderDashboardLogo()}
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2">
                  <div className="flex flex-col space-y-1">
                    {this.renderLeftNavItems(DashboardPage.USERS, [
                      DashboardPage.USERS,
                    ])}
                    {this.renderLeftNavItems(DashboardPage.FIRMWARE, [
                      DashboardPage.FIRMWARE,
                    ])}
                  </div>
                </nav>
              </div>
            </div>

            <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
          </div>
        )}

        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-white">
            {this.renderDashboardLogo()}
            <div className="h-0 flex-1 flex flex-col overflow-y-auto">
              <nav className="px-3 mt-6">
                <div className="flex flex-col space-y-1">
                  {this.renderLeftNavItems(DashboardPage.USERS, [
                    DashboardPage.USERS,
                  ])}
                  {this.renderLeftNavItems(DashboardPage.FIRMWARE, [
                    DashboardPage.FIRMWARE,
                  ])}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </>
    );
  }
}
