import { Component } from "react";
import { connect } from "react-redux";
import { authStateInterface } from "../components/store/reducers/authReducer";
import { withRouter } from "./NavigateWrapper";

interface Props {
  authStore: authStateInterface;
  location: any;
  navigate: any;
}

class SecurityNavigator extends Component<Props> {
  componentDidMount = () => {
    this.handleValidateLoginCondition();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      prevProps.authStore.userLoading !== this.props.authStore.userLoading ||
      prevProps.authStore.userAuth !== this.props.authStore.userAuth ||
      prevProps.authStore.user !== this.props.authStore.user
    ) {
      this.handleValidateLoginCondition();
    }
  };

  handleValidateLoginCondition = () => {
    if (
      !this.props.authStore.userAuth &&
      this.props.location.pathname !== "/"
    ) {
      this.props.navigate("/");
    } else if (
      this.props.authStore.userAuth &&
      this.props.authStore.user?.email &&
      this.props.location.pathname === "/"
    ) {
      this.props.navigate("/dashboard");
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(withRouter(SecurityNavigator));
