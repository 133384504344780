import { Dialog, Transition } from "@headlessui/react";
import React, { Component, Fragment } from "react";
import { withRouter } from "../../navigator/NavigateWrapper";
import {
  addFirmware,
  deleteFirmware,
  handleFirmwareCondition,
} from "../store/actions/firmwareActions";
import Button from "./Button";
import Input from "./Input";
import UploadInput from "./UploadInput";

interface Props {
  open: boolean;
  title: string;
  modalType: string;
  content?: string;
  firmwareId?: string;
  onClose: (visible: boolean, type?: string, content?: string) => void;
  onRefreshData?: () => void;
}

export interface FirmwareState {
  name: string;
  firmware: File | "";
}

export interface FirmwareStateError {
  nameError: string;
  firmwareError: string;
}

interface State {
  firmwareState: FirmwareState;
  firmwareStateError: FirmwareStateError;
  loading: boolean;
}

class ConfirmationModal extends Component<Props> {
  state: State = {
    firmwareState: {
      name: "",
      firmware: "",
    },
    firmwareStateError: {
      firmwareError: "",
      nameError: "",
    },
    loading: false,
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleSubmit = () => {
    const conditionList: string[] = ["name", "firmware"];
    const clonedFirmwareStateError = JSON.parse(
      JSON.stringify(this.state.firmwareStateError)
    );
    handleFirmwareCondition(
      this.state.firmwareState,
      clonedFirmwareStateError,
      conditionList
    );
    this.setState(
      {
        firmwareStateError: clonedFirmwareStateError,
      },
      async () => {
        if (
          !this.state.firmwareStateError.firmwareError &&
          !this.state.firmwareStateError.nameError
        ) {
          this.handleLoading(true);
          await addFirmware(this.state.firmwareState);
          if (this.props.onRefreshData) {
            this.props.onRefreshData();
          }
          this.handleCloseModal();
          this.handleLoading(false);
        }
      }
    );
  };

  handleClearState = () => {
    this.setState({
      firmwareState: {
        name: "",
        firmware: "",
      },
      firmwareStateError: {
        firmwareError: "",
        nameError: "",
      },
      loading: false,
    });
  };

  handleCloseModal = () => {
    this.handleClearState();
    this.props.onClose(false);
  };

  handleCloneFirmwareState = () => {
    const currentFirmware = this.state.firmwareState.firmware;
    const clonedFirmwareState = JSON.parse(
      JSON.stringify(this.state.firmwareState)
    );
    clonedFirmwareState["firmware"] = currentFirmware;

    return clonedFirmwareState;
  };

  handleInputChange = (e: any) => {
    const clonedFirmwareState = this.handleCloneFirmwareState();
    clonedFirmwareState[e.target.id] = e.target.value;
    this.setState({
      firmwareState: clonedFirmwareState,
    });
  };

  handleUploadChange = (e: any) => {
    const clonedFirmwareState = this.handleCloneFirmwareState();
    clonedFirmwareState["firmware"] = e.target.files[0];
    this.setState({
      firmwareState: clonedFirmwareState,
    });
  };

  handleDelete = async () => {
    if (this.props.firmwareId) {
      this.handleLoading(true);
      await deleteFirmware(this.props.firmwareId);
      if (this.props.onRefreshData) {
        this.props.onRefreshData();
      }
      this.handleCloseModal();
      this.handleLoading(false);
    }
  };

  renderContent = () => {
    if (this.props.modalType === "add") {
      return (
        <>
          <div className="font-medium text-sm mt-4 ">Firmware Name</div>
          <div>
            <Input
              id="name"
              className="mt-1"
              placeholder="Example : 0.1.1"
              value={this.state.firmwareState.name}
              error={this.state.firmwareStateError.nameError}
              onChange={this.handleInputChange}
            />
            <div className="font-medium text-sm mt-4 ">Firmware</div>

            <UploadInput
              accept=".bin"
              onChange={this.handleUploadChange}
              file={this.state.firmwareState.firmware}
              error={this.state.firmwareStateError.firmwareError}
            />
          </div>
        </>
      );
    } else {
      return this.props.content;
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <div
          className="fixed z-50 inset-0 overflow-y-auto"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-10 w-10 rounded-full border-4 border-t-4 border-blue-100 loading-spinner" />
                <Transition
                  show={this.state.loading}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="mt-3 text-center sm:mt-5">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Loading
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Please wait while we are uploading the firmware
                      </p>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <Transition.Root show={this.props.open} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.open}
            onClose={this.handleCloseModal}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-10 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className=" border-b border-gray-200 pb-4">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {this.props.title}
                      </Dialog.Title>
                    </div>
                    <div className="my-8 whitespace-pre-line">
                      {this.renderContent()}
                    </div>
                    <div className="flex flex-row justify-end">
                      <Button
                        text="Cancel"
                        type="light"
                        className="mx-4"
                        onClick={this.handleCloseModal}
                      />
                      <Button
                        text="Confirm"
                        type="normal"
                        onClick={
                          this.props.modalType === "add"
                            ? this.handleSubmit
                            : this.handleDelete
                        }
                      />
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      );
    }
  }
}

export default withRouter(ConfirmationModal);
