import { TrashIcon } from "@heroicons/react/outline";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../base/Button";
import Modal from "../base/Modal";
import Table, { TableHeader } from "../base/Table";
import {
  clearFirmware,
  firmwareDataLimit,
  getFirmwaresWithPagination,
} from "../store/actions/firmwareActions";
import { firmwareStateInterface } from "../store/reducers/firmwareReducer";

interface Props {
  firmwareStore: firmwareStateInterface;
  getFirmwaresWithPagination: (paginationStartAt: string | Date) => void;
  clearFirmware: () => void;
}

interface State {
  modalVisible: boolean;
  modalType: string;
  content: string;
  firmwareId: string;
  pageIndex: number;
}

class Firmwares extends Component<Props> {
  state: State = {
    modalVisible: false,
    modalType: "",
    content: "",
    firmwareId: "",
    pageIndex: 1,
  };

  componentDidMount = () => {
    this.handleRefreshData();
  };

  componentWillUnmount() {
    this.props.clearFirmware();
  }

  handleRefreshData = () => {
    this.props.getFirmwaresWithPagination("");
  };

  handlePagination = (value: string) => {
    if (value === "next") {
      if (
        this.props.firmwareStore.lastCursor &&
        this.state.pageIndex * firmwareDataLimit + 1 >
          this.props.firmwareStore.firmwares.length
      ) {
        this.props.getFirmwaresWithPagination(
          this.props.firmwareStore.lastCursor
        );
      }
      this.setState({ pageIndex: this.state.pageIndex + 1 });
    } else {
      this.setState({ pageIndex: this.state.pageIndex - 1 });
    }
  };

  handleModalVisible = (
    visible: boolean,
    modalType?: string,
    content?: string,
    firmwareId?: string
  ) => {
    this.setState({
      modalVisible: visible,
      modalType: modalType,
      content,
      firmwareId,
    });
  };

  renderDataSource = () => {
    let dataSource: Object[] = [];

    if (this.props.firmwareStore.firmwares) {
      this.props.firmwareStore.firmwares.map((eachFirmware) => {
        dataSource.push({
          id: eachFirmware.id,
          firmwareId: (
            <div
              className="cursor-pointer ml-2"
              onClick={this.handleModalVisible.bind(
                this,
                true,
                "delete",
                "Are you sure that you want to delete this firmware? ",
                eachFirmware.id
              )}
            >
              <TrashIcon className="h-5 w-5 block text-blue-600" />
            </div>
          ),
          name: "Version " + eachFirmware.name,
          createdAt: moment(eachFirmware.createdAt.seconds * 1000).format(
            "DD/MM/YY"
          ),
        });
        return "";
      });
    }
    return dataSource;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "name",
        title: "Name",
      },
      {
        key: "createdAt",
        title: "Created",
      },
      {
        key: "firmwareId",
        title: "Action",
      },
    ];

    return (
      <div className="mt-8">
        <Modal
          content={this.state.content}
          open={this.state.modalVisible}
          firmwareId={this.state.firmwareId}
          modalType={this.state.modalType}
          title={
            this.state.modalType === "add" ? "Add Firmware" : "Delete Firmware"
          }
          onClose={this.handleModalVisible}
          onRefreshData={this.handleRefreshData}
        />
        <div className="max-w-6xl mx-auto my-4 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-row items-center">
            <h3 className="text-xl leading-6 font-bold text-gray-900">
              Firmwares
            </h3>
            <div className="flex-grow" />
            <Button
              text="Upload"
              type="normal"
              onClick={this.handleModalVisible.bind(this, true, "add")}
            />
          </div>
        </div>

        <Table
          dataLimit={firmwareDataLimit}
          header={headers}
          loading={this.props.firmwareStore.loading}
          pageIndex={this.state.pageIndex}
          lastCursor={this.props.firmwareStore.lastCursor}
          data={this.renderDataSource()}
          handlePage={this.handlePagination}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    firmwareStore: state.firmwareStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearFirmware: () => dispatch(clearFirmware()),
    getFirmwaresWithPagination: (paginationStartAt: string | Date) =>
      dispatch(getFirmwaresWithPagination(paginationStartAt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Firmwares);
