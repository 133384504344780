import React, { Component } from "react";
import HomeBG from "../../images/home_bg.jpg";
import Logo from "../../images/kaltech_text_logo.png";
import { withRouter } from "../../navigator/NavigateWrapper";
import SecurityNavigator from "../../navigator/SecurityNavigator";
import Button from "../base/Button";
import Input from "../base/Input";
import Overlay from "../base/Overlay";
import { handleAuthCondition, signIn } from "../store/actions/authActions";
import { authStateInterface } from "../store/reducers/authReducer";

export interface AdminState {
  email: string;
  password: string;
}

export interface AdminStateError {
  emailError: string;
  passwordError: string;
}

interface Props {
  location: any;
  authStore: authStateInterface;
}

interface State {
  loading: boolean;
  adminState: AdminState;
  adminStateError: AdminStateError;
}

class Login extends Component<Props> {
  state: State = {
    loading: false,
    adminState: {
      email: "",
      password: "",
    },
    adminStateError: {
      emailError: "",
      passwordError: "",
    },
  };

  handleInputChange = (e: any) => {
    const clonedAdminState = JSON.parse(JSON.stringify(this.state.adminState));
    clonedAdminState[e.target.id] = e.target.value;
    this.setState({
      adminState: clonedAdminState,
    });
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleSubmit = async () => {
    const conditionsList: string[] = ["email", "password"];

    const clonedAdminError = JSON.parse(
      JSON.stringify(this.state.adminStateError)
    );

    handleAuthCondition(
      this.state.adminState,
      clonedAdminError,
      conditionsList
    );

    this.setState(
      {
        adminStateError: clonedAdminError,
      },
      async () => {
        if (
          !this.state.adminStateError.emailError &&
          !this.state.adminStateError.passwordError
        ) {
          this.handleLoading(true);
          const signInError = await signIn({
            email: this.state.adminState.email.replace(/\s/g, ""),
            password: this.state.adminState.password.replace(/\s/g, ""),
          });
          const clonedSignInError = JSON.parse(
            JSON.stringify(this.state.adminStateError)
          );
          clonedSignInError["emailError"] = signInError ? signInError : "";
          this.setState({
            adminStateError: clonedSignInError,
          });
          this.handleLoading(false);
        }
      }
    );
  };

  renderAuthForm() {
    return (
      <>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <div className="mt-1">
            <Input
              id="email"
              className="mt-1"
              placeholder="Enter your email"
              value={this.state.adminState.email}
              error={this.state.adminStateError.emailError}
              onChange={this.handleInputChange}
            />
          </div>
        </div>

        <div className="space-y-1">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <div className="mt-1">
            <Input
              id="password"
              type="password"
              className="mt-1"
              placeholder="Enter your password"
              value={this.state.adminState.password}
              error={this.state.adminStateError.passwordError}
              onChange={this.handleInputChange}
            />
          </div>
        </div>

        <div>
          <Button
            type="normal"
            text="Sign in"
            onClick={this.handleSubmit}
            className="w-full mt-10"
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="min-h-screen bg-white flex font-body">
        <SecurityNavigator location={this.props.location} />
        <Overlay
          loading={this.state.loading}
          text="Please hold on while we are verifying your account"
        />
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="flex flex-row items-center">
                <img className="h-8 w-auto" src={Logo} alt="Kaltech Logo" />
              </div>

              <h2 className="mt-4 text-3xl font-extrabold text-gray-900 font-display">
                Sign in to your account
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <div className="space-y-4">{this.renderAuthForm()}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={HomeBG}
            alt=""
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
