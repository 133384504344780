import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import moment from "moment";
import { FirmwareModel } from "../../../models/Firmware";
import { firestore, storage } from "../../../utils/firebase";
import { FirmwareState, FirmwareStateError } from "../../base/Modal";

export const firmwareDataLimit: number = 10;

export const getFirmwaresWithPagination = (
  paginationStartAfter: string | Date
) => {
  return async (dispatch: any, getState: any) => {
    try {
      let firmwareSnapshot;
      let firmwareCollectionQuery = query(
        collection(firestore, "firmwares"),
        limit(firmwareDataLimit)
      );

      firmwareCollectionQuery = query(
        firmwareCollectionQuery,
        orderBy("createdAt", "desc")
      );

      let firmwareList: FirmwareModel[] = getState().firmwareStore.firmwares;
      const newFirmwareList: FirmwareModel[] = [];

      if (!paginationStartAfter) {
        firmwareList = [];
      }

      updateFirmwareLoadingState(dispatch, true);

      if (paginationStartAfter) {
        firmwareSnapshot = await getDocs(
          query(firmwareCollectionQuery, startAfter(paginationStartAfter))
        );
      } else {
        firmwareSnapshot = await getDocs(firmwareCollectionQuery);
      }

      if (firmwareSnapshot) {
        firmwareSnapshot.forEach((eachDoc: any) => {
          const eachFirmware = eachDoc.data() as FirmwareModel;
          newFirmwareList.push(eachFirmware);
        });
      }

      if (newFirmwareList.length > 0) {
        let lastCursor: string | Date = "";
        if (newFirmwareList.length === firmwareDataLimit) {
          lastCursor = newFirmwareList[firmwareDataLimit - 1].createdAt;
        }

        dispatch({
          type: "UPDATE_FIRMWARE_LIST",
          payload: {
            firmwares: firmwareList.concat(newFirmwareList),
            lastCursor: lastCursor,
          },
        });
      } else {
        dispatch({
          type: "UPDATE_FIRMWARE_LIST",
          payload: {
            firmwares: firmwareList,
            lastCursor: "",
          },
        });
      }

      updateFirmwareLoadingState(dispatch, false);
    } catch (err: any) {
      updateFirmwareLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const clearFirmware = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_FIRMWARE_LIST",
      payload: {
        firmwares: [],
        lastCursor: "",
      },
    });
  };
};

const updateFirmwareLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_FIRMWARE_LOADING",
    payload: {
      loading: loading,
    },
  });
};

export const handleFirmwareCondition = (
  firmwareState: FirmwareState,
  firmwareStateError: FirmwareStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "name":
        if (firmwareState.name.replace(/\s/g, "").length <= 0) {
          firmwareStateError["nameError"] = "Please enter the firmware name";
        } else {
          firmwareStateError["nameError"] = "";
        }
        break;
      case "firmware":
        //TODO: Validate Firmware ext
        if (firmwareState.firmware) {
          const fileSize = firmwareState.firmware.size / 1024 / 1024;
          if (fileSize > 3) {
            firmwareStateError["firmwareError"] = "File size cannot exceed 3MB";
          } else {
            firmwareStateError["firmwareError"] = "";
          }
        } else {
          firmwareStateError["firmwareError"] =
            "Please upload your firmware file";
        }
        break;
    }
    return "";
  });
};

export const addFirmware = async (firmwareState: FirmwareState) => {
  const firmwareId = firmwareState.name.toLowerCase().replaceAll(" ", "_");
  const storageRef = ref(storage, `/firmwares/${firmwareId}/file.bin`);

  if (firmwareState.firmware) {
    try {
      const uploadTask = await uploadBytesResumable(
        storageRef,
        firmwareState.firmware
      );
      const uploadTaskUrl: string = await getDownloadURL(uploadTask.ref);
      const urlParams = new URLSearchParams(uploadTaskUrl);
      const fileToken = urlParams.get("token");
      if (fileToken) {
        const firmwareModel: FirmwareModel = {
          id: firmwareId,
          createdAt: moment().toDate(),
          name: firmwareState.name,
          token: fileToken,
        };

        await setDoc(doc(firestore, "firmwares", firmwareId), firmwareModel);
      }
    } catch (error) {}
  }
};

export const deleteFirmware = async (id: string) => {
  try {
    const storageRef = ref(storage, `/firmwares/${id}/file.bin`);
    await deleteObject(storageRef);
    await deleteDoc(doc(firestore, "firmwares", id));
  } catch (error) {}
};
