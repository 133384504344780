import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../navigator/NavigateWrapper";
import SecurityNavigator from "../../navigator/SecurityNavigator";
import Header from "../dashboard/Header";
import LeftNav, { DashboardPage } from "../dashboard/LeftNav";
import { authStateInterface } from "../store/reducers/authReducer";
import Firmwares from "./Firmwares";
import Users from "./Users";

interface Props {
  authStore: authStateInterface;
  location: any;
  navigate: any;
}

interface State {
  leftNavMobileMenu: boolean;
  page: DashboardPage;
}

class Dashboard extends Component<Props> {
  state: State = {
    leftNavMobileMenu: false,
    page: DashboardPage.USERS,
  };

  componentDidMount() {
    this.handlePageNavigation();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handlePageNavigation();
    }
  }

  handlePageNavigation = () => {
    const path = window.location.pathname;
    let page: DashboardPage = DashboardPage.USERS;
    if (path.includes("dashboard/firmwares")) {
      page = DashboardPage.FIRMWARE;
    }
    this.setState({
      page,
    });
  };

  handleToggleLeftNavMobileMenu = () => {
    this.setState({
      leftNavMobileMenu: !this.state.leftNavMobileMenu,
    });
  };

  renderContent = () => {
    switch (this.state.page) {
      case DashboardPage.USERS:
        return <Users />;
      case DashboardPage.FIRMWARE:
        return <Firmwares />;
      default:
        return null;
    }
  };

  render() {
    return (
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <SecurityNavigator location={this.props.location} />
        {!this.props.authStore.userLoading && this.props.authStore.userAuth && (
          <>
            <LeftNav
              leftNavMobileMenu={this.state.leftNavMobileMenu}
              handleToggleLeftNavMobileMenu={this.handleToggleLeftNavMobileMenu}
              page={this.state.page}
            />
            <div className="flex-1 overflow-auto focus:outline-none">
              <Header
                handleToggleLeftNavMobileMenu={
                  this.handleToggleLeftNavMobileMenu
                }
              />
              <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
                {this.renderContent()}
              </main>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(withRouter(Dashboard));
