import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "../components/pages/Dashboard";
import Login from "../components/pages/Login";
import {
  getAuthListener,
  removeAuthListener,
} from "../components/store/actions/authActions";

declare global {
  interface Date {
    seconds: number;
  }
}

interface Props {
  getAuthListener: () => void;
  removeAuthListener: () => void;
}

function Navigator(props: Props) {
  useEffect(() => {
    props.getAuthListener();
    return () => {
      props.removeAuthListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAuthListener: () => dispatch(getAuthListener()),
    removeAuthListener: () => dispatch(removeAuthListener()),
  };
};

export default connect(null, mapDispatchToProps)(Navigator);
