import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { UserModel } from "../../../models/User";
import { firestore } from "../../../utils/firebase";

export const userDataLimit: number = 10;

export const getUsersWithPagination = (paginationStartAfter: string | Date) => {
  return async (dispatch: any, getState: any) => {
    try {
      let userSnapshot;
      let userCollectionQuery = query(
        collection(firestore, "users"),
        limit(userDataLimit)
      );

      userCollectionQuery = query(
        userCollectionQuery,
        orderBy("createdAt", "desc")
      );

      let userList: UserModel[] = getState().userStore.users;
      const newUserList: UserModel[] = [];

      if (!paginationStartAfter) {
        userList = [];
      }

      updateUserLoadingState(dispatch, true);

      if (paginationStartAfter) {
        userSnapshot = await getDocs(
          query(userCollectionQuery, startAfter(paginationStartAfter))
        );
      } else {
        userSnapshot = await getDocs(userCollectionQuery);
      }

      if (userSnapshot) {
        userSnapshot.forEach((eachDoc: any) => {
          const eachUser = eachDoc.data() as UserModel;
          newUserList.push(eachUser);
        });
      }

      if (newUserList.length > 0) {
        let lastCursor: string | Date = "";
        if (newUserList.length === userDataLimit) {
          lastCursor = newUserList[userDataLimit - 1].createdAt;
        }

        dispatch({
          type: "UPDATE_USER_LIST",
          payload: {
            users: userList.concat(newUserList),
            lastCursor: lastCursor,
          },
        });
      } else {
        dispatch({
          type: "UPDATE_USER_LIST",
          payload: {
            users: userList,
            lastCursor: "",
          },
        });
      }

      updateUserLoadingState(dispatch, false);
    } catch (err: any) {
      updateUserLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const clearUsers = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_USER_LIST",
      payload: {
        users: [],
        lastCursor: "",
      },
    });
  };
};

const updateUserLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_USER_LOADING",
    payload: {
      loading: loading,
    },
  });
};
