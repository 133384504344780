import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { auth, firestore } from "../../../utils/firebase";
import { AdminState, AdminStateError } from "../../pages/Login";

//INFO : Auth Listener
let unsubscribeAuthListener: any = null;
let unsubscribeUserListener: any = null;

export const getAuthListener = () => {
  return async (dispatch: any, getState: any) => {
    if (!unsubscribeAuthListener) {
      unsubscribeAuthListener = onAuthStateChanged(auth, (user: any) => {
        if (user) {
          dispatch({
            type: "UPDATE_USER_AUTH",
            payload: {
              userAuth: user,
            },
          });
          getUserInfoListener(dispatch);
        } else {
          dispatch({
            type: "UPDATE_USER_AUTH",
            payload: {
              userAuth: null,
            },
          });
          removeUserInfoListener();
        }
      });
    }
  };
};

export const removeAuthListener = () => {
  return async (dispatch: any, getState: any) => {
    if (unsubscribeAuthListener) {
      unsubscribeAuthListener();
      unsubscribeAuthListener = null;
    }
    removeUserInfoListener();
  };
};

export const signIn = async (credentials: {
  email: string;
  password: string;
}) => {
  try {
    await signInWithEmailAndPassword(
      auth,
      credentials.email,
      credentials.password
    );
    return "";
  } catch (err: any) {
    return err.message;
  }
};

export const handleSignOut = async () => {
  try {
    await signOut(auth);
  } catch (err) {}
};

const getUserInfoListener = async (dispatch: any) => {
  try {
    if (auth.currentUser?.uid) {
      try {
        const adminQuery = await getDoc(
          doc(firestore, "admin", auth.currentUser?.uid)
        );

        if (!adminQuery.exists()) {
          handleSignOut();
        }
      } catch (err) {
        handleSignOut();
      }

      unsubscribeUserListener = onSnapshot(
        doc(firestore, "admin", auth.currentUser?.uid),
        (doc: any) => {
          if (doc) {
            dispatch({
              type: "UPDATE_USER",
              payload: {
                user: doc.data(),
              },
            });
          }
        }
      );
    }
  } catch (err) {}
};

const removeUserInfoListener = () => {
  if (unsubscribeUserListener) {
    unsubscribeUserListener();
    unsubscribeUserListener = null;
  }
};

export const handleAuthCondition = (
  adminAttributeState: AdminState,
  adminAttributeError: AdminStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "email":
        /* eslint-disable */
        const filter =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        /* eslint-enable */
        if (!filter.test(adminAttributeState.email.replace(/\s/g, ""))) {
          adminAttributeError["emailError"] =
            "Please enter your email in the correct format";
        } else {
          adminAttributeError["emailError"] = "";
        }
        break;
      case "password":
        if (adminAttributeState.password.replace(/\s/g, "").length < 6) {
          adminAttributeError["passwordError"] =
            "Please enter at least 6 characters";
        } else {
          adminAttributeError["passwordError"] = "";
        }
        break;
    }
    return null;
  });
};
