import { PhotographIcon } from "@heroicons/react/solid";
import React, { Component } from "react";

interface Props {
  accept?: string;
  error?: string;
  file?: File | "";
  fileDescription?: string;
  onChange?: (e: any) => void;
}

export default class UploadInput extends Component<Props> {
  handleOnChange = (e: any) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  renderFileDescription = () => {
    if (this.props.fileDescription) {
      return this.props.fileDescription;
    } else {
      return "Insight Camera Firmware";
    }
  };

  render() {
    const defaultBorderColor = " border-gray-300";
    const errorBorderColor = "border-red-300";
    return (
      <div className="sm:mt-1 sm:col-span-2">
        <div
          className={`${
            this.props.error ? errorBorderColor : defaultBorderColor
          } border-2 max-w-lg flex justify-center px-6 pt-5 pb-6  border-dashed rounded-md`}
        >
          <div className="space-y-1 text-center">
            <PhotographIcon className="mx-auto h-12 w-12 text-gray-300" />
            <div className="flex text-sm text-gray-600 justify-center">
              <label className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                <span>Upload a file</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  accept={this.props.accept}
                  onChange={this.handleOnChange}
                />
              </label>
            </div>
            <p className="text-xs text-gray-500">
              {this.props.file
                ? `${this.props.file.name}`
                : this.renderFileDescription()}
            </p>
          </div>
        </div>
        <span className="text-red-500 text-sm">{this.props.error}</span>
      </div>
    );
  }
}
