import { FirmwareModel } from "../../../models/Firmware";

export interface firmwareStateInterface {
  firmwares: FirmwareModel[];
  lastCursor: string | Date;
  loading: boolean;
}

const initState: firmwareStateInterface = {
  firmwares: [],
  lastCursor: "",
  loading: true,
};

const firmwareReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_FIRMWARE_LIST":
      return {
        ...state,
        firmwares: action.payload.firmwares,
        lastCursor: action.payload.lastCursor,
      };
    case "UPDATE_FIRMWARE_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default firmwareReducer;
